@import '~antd/dist/antd.less';
@import '../../assets/style/index.less';

.app-container {
  height: 100vh;
}

.content-layout {
  height: 100vh;
  overflow-y: auto;
}
.app-content {
  box-sizing: border-box;
  padding: 16px;
}
.topNav {
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 999;
}

.themeLight {
  background-color: #FFF;
  color: rgba(0, 0, 0, 0.85);
}
.themeDark {
  color: #FFF;
}