.userInfoSkeleton {
  display: flex;
  align-items: center;
  height: 64px;
  :global {
    .ant-skeleton {
      margin-right: 16px;
      line-height: 32px;
    }
  }
}

.username {
  cursor: pointer;
}

.__dark {
  color: white;
}
.light {
  color: rgba(0, 0, 0, 0.85);
}
